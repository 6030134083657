// import logo from "./logo.svg";
// import "./App.css";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { toast } from "material-react-toastify";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Courses from "../components/home/Courses";
import IconHeadphones from "../components/svgAssets/IconHeadphones";
import IconQuestion from "../components/svgAssets/IconQuestion";
import IconSpeaker from "../components/svgAssets/IconSpeaker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import TimePicker from "@mui/lab/TimePicker";
// import DateTimePicker from "@mui/lab/DateTimePicker";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import MobileDatePicker from "@mui/lab/MobileDatePicker";
// import StaticDatePicker from "@mui/lab/StaticDatePicker";
// import StaticTimePicker from "@mui/lab/StaticTimePicker";
import LineWaveRed from "../components/svgAssets/LineWaveRed";
import { contactUs } from "../redux/action/utils.action";
import { Link } from "react-router-dom";

function Edu() {
  const classes = useStyle();
  const cart = useSelector((state) => state.cart.items);
  const [bookingData, setBookingData] = useState({});
  const [dateValue, setDateValue] = useState(undefined);
  const [timeValue, setTimeValue] = useState(undefined);
  const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef();
  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      phone: "",
      email: "",
      message: "",
    },
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      setSubmitLoading(true);
      contactUs(values)
        .then((data) => {
          toast.success(data.message, { position: "bottom-right" });
          console.log("resetting");
          formRef.current.reset();
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    },
  });
  const [lastLocations, setLastLocations] = useState([
    {
      continentName: "NORTH AMERICA",
      locationArray: [
        "Las Vegas, Nevada",
        " Miami Florida",
        "Gilbert, Arizona",
        "Los Angeles, California",
        "Fort Wayne, California",
        "Chicago, Illinois",
        "New York, New York",
      ],
    },

    {
      continentName: "EUROPE",
      locationArray: [
        "Amsterdam, Netherlands",
        "Geneva, Switzerland",
        "Cologne, Germany",
        "Berlin, Germany",
        "London, England",
        "Milan, Italy",
        "Rome, Italy",
      ],
    },
    {
      continentName: "ASIA",
      locationArray: ["Beijing, China"],
    },
  ]);

  const seriesList = [{}, {}, {}, {}, {}, {}, {}];
  return (
    <div className={classes.home}>
      <Helmet>
        <title>Studio DMI | Mixing and Mastering Workshop Tour 2024</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Studio DMI and Luca are passionate about the future of music and the industry, making contributions through live workshop tours, webinars and participating as a partner of MyMixLab.com, a website dedicated to sharing tricks and tips by various producers, engineers and artists."
        />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <div
        className={classes.homeImageDiv}
        sx={{ height: { xs: "70vh", sm: "auto" } }}
      >
        <Box sx={{ height: { xs: "70vh", sm: "auto" } }} width="100%">
          <img
            src="/images/edu/tour_24/TourDesktopBG.jpg"
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className={classes.topContent}
          sx={{ padding: { xs: "20px 30px", sm: "50px 100px" } }}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="right"
            sx={{
              paddingTop: { xs: 0, lg: "20rem" },
              paddingBottom: { xs: 0, lg: "00px" },
              paddingRight: { xs: 0, lg: "10rem" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "50%" },
              }}
            >
              <img src="/images/edu/tour_24/CloserLogoTour.png" width="100%" height="auto" />
            </Box>
          </Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            sx={{
              paddingBottom: { xs: 0, lg: "00px" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "60%" },
              }}
            >
              <img src="/images/edu/tour_24/TourTextOverlay.png" width="120%!important" height="auto" />
            </Box>
          </Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            sx={{
              paddingBottom: { xs: 0, lg: "240px" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "50%" },
              }}
              display={"flex"}
              justifyContent={"center"}
            >
              <img src="/images/logo-studio-dmi-white-red.png" style={{ maxWidth: "40%" }} height="auto" />
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={{ display: { xs: "none", lg: "flex" } }}
          className={classes.sideContent}
        >
        </Box>
      </div>
      {/* Course Section */}
      <Courses />
      {/* End Course Section */}
      <Box
        className={classes.homeImageDiv}
        // sx={{ height: { xs: "70vh", sm: "auto" } }}
        style={{
          backgroundImage:
            "url(/images/edu/tour_24/CourseDescription.jpg)",
          backgroundPosition: "center",
          backgroundColor: "rgba(0, 0, 0, .7)",
          backgroundBlendMode: "darken",
          paddingBottom: "200px"
        }}
      >
        {/* <Box sx={{ height: { xs: "70vh", sm: "auto" } }} width="100%">
          <img
            src="/images/edu/tour_24/CourseDescription.jpg"
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        </Box> */}
        <Box
          // backgroundColor="rgba(0, 0, 0, .2);"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          sx={{
            padding: { xs: "40px 30px", sm: "60px 100px" },
            height: { xs: "auto", md: "auto" },
            width: "100%"
            // backgroundPosition: "center",
            // backgroundBlendMode: "darken",
            // backgroundRepeat: "repeat-y",
            // backgroundSize: "cover"
          }}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            sx={{
              paddingTop: { xs: 0, lg: 0 },
              paddingBottom: { xs: 0, lg: "00px" },
              paddingRight: { xs: 0, lg: "0rem" },
              height: "100%"
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "70%", lg: "60%" },
              }}
              color={"#FFFF"}
            >
              <Typography variant="body1" style={{ fontSize: "1.5rem", marginBottom: 4 }} align="left">
                Closer Music presents - Luca Pretolesi’s Mixing and Masterging Workshop Tour 2024 Highlights…
              </Typography>
              <Typography variant="body1" style={{ fontSize: "1.5rem", marginBottom: 4 }} align="left">
                Thanks so much to everyone who played a part in making this the most epic workshop tour to date! Such an incredible experience seeing old friends and making new ones along the way sharing in these incredible moments of Luca’s educational passion with the world.
              </Typography>
              <Typography variant="body1" style={{ fontSize: "1.5rem", marginBottom: 4 }} align="left">
                A special thanks to Closer Music for presenting the tour and all of our partners – MAT Academy, The Abbey Road Institutes in Amsterdam and Paris, Icon Collective, H-Farm, Focal, Algam EKO, Acustica, Dolby and MyMixLab.
              </Typography>
              <Typography variant="body1" style={{ fontSize: "1.5rem", marginBottom: 4 }} align="left">
                See you all next time and stay tuned!
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <div className={classes.footer}>
        <Box
          sx={{
            marginTop: "32px",
            padding: { xs: "16px", sm: "18px", md: "32px" },
            height: { xs: "200px", md: "300px" },
            width: { xs: "80%", sm: "70%", md: "60%", lg: "60%" },
          }}
          display={"flex"}
          justifyContent={"center"}
          alignContent={"center"}
          bgcolor={"black"}
        >
          <img src="/images/logo-studio-dmi-white-red.png" style={{ width: "20%", objectFit: "contain" }} height="auto" />
        </Box>
        <Typography variant="subtitle1" color={"#FFFF"} textAlign={"center"} marginBottom={"16px"}>
          For Workshop inquiries Please Contact <a href="mailto:tommy@studiodmi.com">tommy@studiodmi.com</a>
        </Typography>
      </div>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  home: {
    textAlign: "center",
    justifyContent: "center",
  },
  homeImageDiv: {
    position: "relative",
    width: "100%",
    zIndex: 112,
    marginBottom: -7,
  },
  seriesList: {
    position: "relative",
    zIndex: 113,
    marginBottom: -7,
  },
  listSelection: {
    height: "100%",
  },
  topContent: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 112,
  },
  bottomContent: {
    position: "absolute",
    bottom: 60,
    left: 0,
    padding: "0 100px",
    zIndex: 113,
  },

  onlineMentoring: {
    backgroundColor: theme.palette.background.main,
  },
  userForm: {
    backgroundColor: theme.palette.secondary.main,
  },
  //sideContent: {
  //    position: "absolute",
  //    height: "99.5%",
  //    top: 0,
  //    right: 0,
  //    background: `linear-gradient(90deg, rgba(0,0,0,0) 0%,rgba(0,0,0,1)  93.76%)`,
  //    paddingLeft: 600,
  //    paddingRight: 100,
  //    zIndex: 112,
  //    // backdropFilter: "blur(6px)",
  //},
  rotatedButton: {
    backdropFilter: "inherit",
  },
  headingText: {
    fontWeight: 600,
  },
  labelText: {
    backgroundColor: theme.palette.secondary.main,
    padding: "2px 14px",
  },
  verticalText: {
    transform: "rotate(-90deg)",
  },
  borderText: {
    "-webkit-text-stroke": "1px #1A1A30",
  },
  promoCodeBox: {
    backgroundColor: "#AAC9DD",
    borderRadius: "44px",
  },
  customAddIcon: {
    border: "1px solid #fff",
    borderRadius: "25px",
    color: "#fff",
  },
  customDivider: {
    height: "2px",
    background: theme.palette.secondary.main,
    width: "50%",
  },
  testimonialCard: {
    borderRadius: "5px",
    width: "100%",
  },
  tour_ul: {
    marginInline: "auto",
    zIndex: 114,
    "@media (max-width: 460px)": {
      marginInline: "unset",
    },
  },
  tour_list: {
    display: "flex",
    gap: "24px",
    alignItems: "flex-start",
  },
  tour_city: {
    width: "130px",
    color: "#FF3131",
    fontWeight: 600,
    textShadow: "1px 1px 1px #000",
    "@media (max-width: 576px)": {
      width: "102px",
    },
  },
  tour_venue: {
    width: "108px",
    textShadow: "1px 1px 2px #000",
    "@media (max-width: 576px)": {
      width: "84px",
    },
  },
  tour_date: {
    width: "92px",
    color: "#FF3131",
    fontWeight: 600,
    textShadow: "1px 1px 1px #000",
    "@media (max-width: 576px)": {
      width: "72px",
    },
  },
  tour_btn: {
    width: "108px",
    cursor: "pointer",
    background: "black",
    border: "1px solid #FFF8F8",
    boxShadow: "0px 0px 5px 1px #ff3131,inset 0px 0px 5px 1px #ff3131",
    color: "#FFFF",
    textShadow: "1px 1px 2px #313131",
    fontWeight: 500,
    borderRadius: "2px",
  },
  workshopBuyBtn: {
    padding: "16px 12px",
    width: "256px",
    cursor: "pointer",
    background: "transparent",
    color: "#fff",
    border: "1px solid #FFF8F8",
    boxShadow: "0px 0px 10px 2px #ff3131,inset 0px 0px 10px 2px #ff3131",
    borderRadius: "4px",
    position: "relative",
    marginBottom: "24px",
  },
  redoutlinedBtn: {
    color: "#fff",
    border: "1px solid #fff",
    "&:hover": {
      border: "1px solid #fff",
    },
  },
  footer: {
    backgroundImage: "linear-gradient(0deg, black 50%, transparent)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    justifyItems: "space-between",
    alignItems: "center",
    paddingBottom: 4,
    color: "white",
    height: "400px",
    marginTop: "-200px",
    zIndex: '1000'
  }
}));

export default Edu;
