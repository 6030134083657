import React, { createContext, useContext, useState } from 'react';
import { shopifyRequest } from '../lib/shopify';

/**
 * This context uses Shopify's new Cart endpoints instead of the deprecated Checkout API.
 */

const ShopContext = createContext();

export const ShopProvider = ({ children }) => {
  const [cartId, setCartId] = useState(null);
  const [cart, setCart] = useState(null);

  /**
   * Creates a new cart in Shopify if no cart currently exists,
   * and returns the cartId. Otherwise, returns the existing ID.
   */
  const getOrCreateCart = async (forceCreateNew = false) => {
    if (cartId) {
      if (!forceCreateNew)
        return cartId;
    }

    const query = `
      mutation CartCreate($input: CartInput!) {
        cartCreate(input: $input) {
          cart {
            id
            checkoutUrl
            lines(first: 25) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                      price {
                        amount
                      }
                      product {
                        title
                        description
                      }
                    }
                  }
                }
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }
    `;
    const variables = {
      input: {},
    };
    const res = await shopifyRequest(query, variables);
    const newCart = res.data.cartCreate.cart;
    setCartId(newCart.id);
    setCart(newCart);
    return newCart.id;
  };

  /**
   * Adds a product variant to the cart.
   */
  const addToCart = async (merchandiseId, quantity = 1) => {
    const currentCartId = await getOrCreateCart();
    const query = `
      mutation CartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
          cart {
            id
            checkoutUrl
            lines(first: 25) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                      price {
                        amount
                      }
                      product {
                        title
                        description
                      }
                    }
                  }
                }
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }
    `;
    const variables = {
      cartId: currentCartId,
      lines: [{ merchandiseId, quantity }],
    };
    const res = await shopifyRequest(query, variables);
    console.log(res)
    const updatedCart = res.data.cartLinesAdd.cart;
    setCart(updatedCart);
  };

  /**
   * Removes an item (line) from the cart by lineId.
   */
  const removeFromCart = async (lineId) => {
    if (!cartId) return;
    const query = `
      mutation CartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            id
            checkoutUrl
            lines(first: 25) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                      price {
                        amount
                      }
                      product {
                        title
                        description
                      }
                    }
                  }
                }
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }
    `;
    const variables = {
      cartId,
      lineIds: [lineId],
    };
    const res = await shopifyRequest(query, variables);
    const updatedCart = res.data.cartLinesRemove.cart;
    setCart(updatedCart);
  };

  /**
   * Updates the cart by removing all items.
   * Alternatively, you could iterate line IDs to remove them one by one.
   */
  const clearCart = async () => {
    if (!cart?.lines?.edges?.length) return;
    const lineIds = cart.lines.edges.map(edge => edge.node.id);
    const query = `
      mutation CartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            id
            checkoutUrl
            lines(first: 25) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                      price {
                        amount
                      }
                      product {
                        title
                        description
                      }
                    }
                  }
                }
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }
    `;
    const variables = {
      cartId,
      lineIds,
    };
    const res = await shopifyRequest(query, variables);
    const updatedCart = res.data.cartLinesRemove.cart;
    setCart(updatedCart);
  };

  /**
   * Returns the checkoutUrl to allow customers to complete their transaction.
   */
  const getCheckoutUrl = () => {
    const checkoutUrl = cart?.checkoutUrl || '';
    return checkoutUrl;
  };

  const refreshCart = async () => {
    await getOrCreateCart(true);
  }
  // Load cart data from localStorage on component mount
  React.useEffect(() => {
    const storedCartId = localStorage.getItem('cartId');
    const storedCart = localStorage.getItem('cart');

    if (storedCartId) {
      setCartId(storedCartId);
    }
    if (storedCart) {
      setCart(JSON.parse(storedCart));
    }
  }, []);

  // Update localStorage whenever the cart changes
  React.useEffect(() => {
    if (cartId) {
      localStorage.setItem('cartId', cartId);
    }
    if (cart) {
      localStorage.setItem('cart', JSON.stringify(cart));
    }
  }, [cart, cartId]);

  return (
    <ShopContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        clearCart,
        getCheckoutUrl,
        refreshCart,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

export const useShop = () => useContext(ShopContext);