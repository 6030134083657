import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import Loading from "./components/utils/Loading";
import store from "./redux/store";
import AppRoutes from "./routes";
import { ShopProvider } from "./context/Shopcontext";
function App() {
  return (
    <Provider store={store}>
      <ShopProvider>
      <Loading />
      <Router>
        <Layout>
          <AppRoutes />
        </Layout>
      </Router>
      </ShopProvider>
    </Provider>
    // <AppRoutes />
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //     <Typography variant="h4" color="textSecondary" className="App-link">
    //       Master LOGO
    //     </Typography>
    //   </header>
    //   <div style={{ backgroundColor: "#fff", marginTop: 50 }}>
    //     <TextField
    //       id="some text field"
    //       placeholder="some text field"
    //       variant="outlined"
    //     />
    //     <br />
    //     <br />
    //     <Button variant="contained" color="primary">
    //       Some Button
    //     </Button>
    //   </div>
    // </div>
  );
}

export default App;
