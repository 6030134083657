import React, { useEffect, useState } from 'react';
import { shopifyRequest } from '../../lib/shopify';
import { useShop } from '../../context/Shopcontext';
import { Link } from 'react-router-dom';

/**
 * Basic product list page that fetches products using the Storefront API,
 * then allows adding a product’s default variant to the cart.
 */

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const { addToCart } = useShop();

  useEffect(() => {
    async function fetchProducts() {
      const query = `
        query {
          products(first: 10) {
            edges {
              node {
                id
                title
                description
                tags
                images(first: 5) {
                  nodes {
                    url
                  }
                }
                type: metafield(namespace: "custom", key: "type") {
                  type
                  value
                }
                external: metafield(namespace: "custom", key: "external") {
                  type
                  value
                }
                url: metafield(namespace: "custom", key: "url") {
                  type
                  value
                }
                is_bundle: metafield(namespace: "custom", key: "is_bundle") {
                  type
                  value
                }
                
                variants(first: 1) {
                  edges {
                    node {
                      id
                      title
                      price {
                        amount
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `;
      const res = await shopifyRequest(query);
      const edges = res.data.products.edges;
      setProducts(edges.map(edge => edge.node).filter(product => product.type.value === 'Plugin'));
    }

    fetchProducts();
  }, []);

  const handleAddToCart = (variantId) => {
    console.log(variantId)
    addToCart(variantId, 1);
  };

  console.log(products);

  return (
    <div>
      <h1>Products</h1>
      <ul>
        {products.map(product => {
          const defaultVariant = product.variants.edges[0]?.node;
          return (
            <li key={product.id}>
              <h2>{product.title}</h2>
              <p>{product.description}</p>
              {defaultVariant && (
                <div>
                  <p>Price: ${defaultVariant.price.amount}</p>
                  <button onClick={() => handleAddToCart(defaultVariant.id)}>
                    Add to Cart
                  </button>
                </div>
              )}
            </li>
          );
        })}
      </ul>
      <Link to={"/shopify-cart"}>Go TO cart</Link>
    </div>
  );
};

export default ProductList;