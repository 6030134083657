import { Box, Link, Typography } from "@mui/material";

const PluginBox = (props) => {
    const { pluginImage, url, pluginName, gradientString } = props;
    return (
      <div>
        <Link
          href={url}
          target="_blank"
          sx={{ textDecoration: "none !important" }}
        >
          <Box
            sx={{
              cursor: "pointer",
            }}
          >
            <Box
              position={"relative"}
              sx={{
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  height: "261px",
                  borderRadius: "10px",
                  background: gradientString,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  display: "flex",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <img
                  src={pluginImage}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    backdropFilter: "blur(20px)",
                  }}
                />
              </Box>
              <Box
                sx={{
                  borderRadius: "10px",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  background: gradientString,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Typography
                  align="center"
                  variant="h5"
                  color="common.white"
                  sx={{
                    textDecoration: "none !important",
                    textTransform: "uppercase",
                    padding: "0 50px",
                    fontWeight: 700,
                  }}
                >
                  {pluginName}
                </Typography>
              </Box>
            </Box>
  
            <Box display="flex" justifyContent="center" marginTop={"26px"}>
              <div className="projectBtnDrawer">
                <center>
                  <div className="btn-secondary" style={{ padding: "14px 52px" }}>
                    EXPLORE
                  </div>
                </center>
              </div>
            </Box>
          </Box>
        </Link>
      </div>
    );
  };
  export default PluginBox;