import { useEffect, useState } from "react"
import PluginBox from "../../components/plugins/plugin-box"
import { Box, Grid } from "@mui/material"
import { FetchProducts } from "../../redux/action/shop.action"
// import StorefrontClient from "../../shopify/client"

export default function ProductList() {
    const [products, setProducts] = useState([])

    useEffect(() => {
        FetchProducts()
        .then(res=>{
            console.log(res.data)
            setProducts(res.data)
        })
        .catch(err=>console.log(err))
    }, [])
    return (
        <>
            <Box
                sx={{
                    mt: 12,
                    backgroundImage: `url(/images/tech/bgr-tech1.png)`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    minHeight: "100vh"
                }}
            >
                <Grid
                    container
                    spacing={{ xs: 3, md: 5 }}
                    direction="row"
                    justifyContent={"center"}
                    sx={{ padding: { xs: "30px 10px", sm: "50px" } }}
                >
                    {
                        products.map((product, index) => (
                            <Grid key={index} item xs={12} md={6} lg={5} xl={4} >
                                <PluginBox
                                    url={`/products/${product.handle}`}
                                    gradientString={
                                        "linear-gradient(110.04deg, rgba(240, 48, 48, 0.6) 26.7%, rgba(10, 27, 72, 0.6) 66.6%, rgba(34, 5, 5, 0.6) 89.75%)"
                                    }
                                    pluginImage={product.featuredImage?.url}
                                    pluginName={product.title}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
        </>
    )
}