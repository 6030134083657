import React from "react";
import {
  Route, Routes
} from "react-router-dom";
import NoPageFound from "../pages/404";
import Auth from "../pages/auth";
import ContactUs from "../pages/ContactUs";
import Edu from "../pages/edu";
import FeaturedWork from "../pages/fw";
import Media from "../pages/media";
import ResetPassword from "../pages/resetPassword";
import Studios from "../pages/studios.jsx";
import Tech from "../pages/tech";
import Home from "../pages/home";
import UserVideoCourse from "../pages/userVideoCourse";
import VerifyAccount from "../pages/verifyAccount";
import Product from "../pages/products/product";
import TourMiami from "../pages/tours/miami.jsx";
import TourAmsterdam from "../pages/tours/amsterdam.jsx";
import TourWarsaw from "../pages/tours/warsaw.jsx";
import ProductList from "../pages/products/list.jsx";
import SingleProduct from "../pages/products/single-product.jsx";
import PluginActivation from "../pages/tutorials/plugin-activation.jsx";
import Products1 from "../pages/products/products1";

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
function AuthRoutes() {
  return (
    <Routes>
      <Route exact path="/edu" element={<Edu />} />
      <Route exact path="/auth" element={<Auth />} />
      <Route exact path="/tech" element={<Tech />} />
      <Route exact path="/media" element={<Media />} />
      <Route exact path="/contactus" element={<ContactUs />} />
      <Route exact path="/products/:id" element={<SingleProduct />} />
      <Route exact path="/products" element={<ProductList />} />
      <Route exact path="/products1" element={<Products1 />} />
      <Route exact path="/tutorials/plugin-activation" element={<PluginActivation />} />
      <Route exact path="/tours/miami" element={<TourMiami />} />
      <Route exact path="/tours/amsterdam" element={<TourAmsterdam />} />
      <Route exact path="/tours/warsaw" element={<TourWarsaw />} />
      <Route exact path="/reset-password/:token" element={<ResetPassword />} />
      <Route exact path="/verify-account/:token" element={<VerifyAccount />} />
      <Route exact path="/" element={<Home />} />
      <Route exact path="/featured-work" element={<FeaturedWork />} />
      <Route exact path="/studios" element={<Studios />} />

      <Route exact path="/payment" element={<Auth />} />
      <Route
        exact
        path="/edu/courses/:cid/:vid"
        element={<UserVideoCourse />}
      />
      <Route path="*" element={<NoPageFound />} />
    </Routes>
  );
}

export default AuthRoutes;
