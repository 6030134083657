import Client from 'shopify-buy';

const client = Client.buildClient({
  domain: "my-test-store-vi.myshopify.com",
  storefrontAccessToken: "79fb3f1e7d36bb84e49c79d719b9b2df",
});

export default client;

/**
 * Shopify Storefront API setup using the new cart endpoints.
 * Replace 'your-store-name.myshopify.com' and 'your-storefront-access-token'
 * with your actual Shopify store domain and storefront API token.
 */
const STOREFRONT_API_URL = 'https://my-test-store-vi.myshopify.com/api/2023-07/graphql.json';
const STOREFRONT_ACCESS_TOKEN = '79fb3f1e7d36bb84e49c79d719b9b2df';

export async function shopifyRequest(query, variables = {}) {
  const res = await fetch(STOREFRONT_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
    },
    body: JSON.stringify({ query, variables }),
  });
  return res.json();
}