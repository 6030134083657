import { Box } from "@mui/material";

export default function PluginActivation() {
    return (
        <>
            <div style={{ width: "100%", "padding": "20px", marginTop: "120px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ width: { xs: "100%", md: "80%" } }} maxWidth={"md"}>
                    <table data-identifyelement="480" style={{ "width": "100%" }}>
                        <tbody data-identifyelement="481">
                            <tr data-identifyelement="482">
                                <td data-identifyelement="483" style={{ "width": "100%", textAlign: "center", backgroundColor: "rgb(247, 218, 100)" }}>
                                    <p data-identifyelement="484" dir="ltr"><span data-identifyelement="485" dir="ltr" style={{ fontSize: "14px" }}>Redeem your Fire the Gold using an activation code</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ol>
                        <li>Create a user account on the Acustica Audio website if you do not have one yet.</li>
                        <li>Download and install the Aquarius Desktop application (ADA) if you do not have it installed yet.</li>
                        <li>Run and log-in to the Aquarius Desktop application (ADA) using your email user-name and password.</li>
                        <li>Click on the settings icon.</li>
                        <li>Click on the activations tab.</li>
                        <li>Insert your activation code in the activation code field.</li>
                        <li>Click on the apply button. Aquarius Desktop (ADA) will deposit Fire the Gold on your account.</li>
                        <li>Click on save.</li>
                    </ol>
                    <p style={{ textAlign: "left", borderLeft: "6px solid #e86f25", borderTop: "1px solid #8e8888", borderBottom: "1px solid #8e8888", borderRight: "1px solid #8e8888", padding: "12px 0px 12px 12px", borderRadius: "4px", fontSize: "14px", fontWeight: "400", textAlign: "left", textIndent: "0px", display: "inline !important" }}>
                        THE ACTIVATION CODE "PUMPKIN2022" IS AN EXAMPLE ACTIVATION CODE. YOU MUST USE YOUR ONE
                    </p>
                    <img src="/images/plugin_install_1.png" style={{maxWidth:"100%"}} />
                    <p style={{ textAlign: "left", borderLeft: "6px solid #2c5cc5", borderTop: "1px solid #8e8888", borderBottom: "1px solid #8e8888", borderRight: "1px solid #8e8888", padding: "12px 0px 12px 12px", borderRadius: "4px", fontSize: "14px", fontWeight: "400", textAlign: "left", textIndent: "0px", display: "inline !important" }}>
                        Learn more about the Aquarius Desktop application by reading the online <a href="https://acusticaudio.freshdesk.com/support/solutions/articles/35000185197-aquarius-desktop-application-online-user-guide"> user guide.</a>
                    </p>

                    <table data-identifyelement="480" style={{ "width": "100%" }}>
                        <tbody data-identifyelement="481">
                            <tr data-identifyelement="482">
                                <td data-identifyelement="483" style={{ "width": "100%", textAlign: "center", backgroundColor: "rgb(247, 218, 100)" }}>
                                    <p data-identifyelement="484" dir="ltr"><span data-identifyelement="485" dir="ltr" style={{ fontSize: "14px" }}>Install the Fire Gold</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ol>
                        <li>Run the Aquarius Desktop Application (ADA)</li>
                        <li>Go to the Purchased page</li>
                        <li>In the top search filter, type "Fire the Gold" (without the quotes)</li>
                        <li>Select the format more convenient for your audio host (DAW or NLE), for example, VST3 x64</li>
                        <li>Click on Install</li>
                    </ol>
                    <img src="/images/plugin_install_2.png" style={{maxWidth:"100%"}} />
                    <p style={{ textAlign: "left", borderLeft: "6px solid #2c5cc5", borderTop: "1px solid #8e8888", borderBottom: "1px solid #8e8888", borderRight: "1px solid #8e8888", padding: "12px 0px 12px 12px", borderRadius: "4px", fontSize: "14px", fontWeight: "400", textAlign: "left", textIndent: "0px", display: "inline !important" }}>
                        For other audio hosts (DAW or NLE) drop-down the menu and select the right plug-in format.
                    </p>
                    <table data-identifyelement="480" style={{ "width": "100%" }}>
                        <tbody data-identifyelement="481">
                            <tr data-identifyelement="482">
                                <td data-identifyelement="483" style={{ "width": "100%", textAlign: "center", backgroundColor: "rgb(247, 218, 100)" }}>
                                    <p data-identifyelement="484" dir="ltr"><span data-identifyelement="485" dir="ltr" style={{ fontSize: "14px" }}>Run Fire the Gold on Cubase</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ol>
                        <li>Run Cubase</li>
                        <li>Create a new audio track</li>
                        <li>Go to the track insert slots</li>
                        <li>Add a new plug-in and use the search to find Fire the Gold</li>
                    </ol>
                    <img src="/images/plugin_install_3.png" style={{maxWidth:"100%"}} />
                    <table data-identifyelement="480" style={{ "width": "100%" }}>
                        <tbody data-identifyelement="481">
                            <tr data-identifyelement="482">
                                <td data-identifyelement="483" style={{ "width": "100%", textAlign: "center", backgroundColor: "rgb(247, 218, 100)" }}>
                                    <p data-identifyelement="484" dir="ltr"><span data-identifyelement="485" dir="ltr" style={{ fontSize: "14px" }}>Run Fire the Gold on Logic Pro</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ol>
                        <li>Run Logic Pro.</li>
                        <li>Create a new audio track.</li>
                        <li>Go to the track insert slots.</li>
                        <li>Go Audio Units, Acustica, FIRETHEGOLD x64.</li>
                        <li>Select FIRETHEGOLD x64.</li>
                    </ol>
                    <img src="/images/plugin_install_4.png" style={{maxWidth:"100%"}} />
                    <table data-identifyelement="480" style={{ "width": "100%" }}>
                        <tbody data-identifyelement="481">
                            <tr data-identifyelement="482">
                                <td data-identifyelement="483" style={{ "width": "100%", textAlign: "center", backgroundColor: "rgb(247, 218, 100)" }}>
                                    <p data-identifyelement="484" dir="ltr"><span data-identifyelement="485" dir="ltr" style={{ fontSize: "14px" }}>Run Fire the Gold on Pro Tools</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ol>
                        <li>Run Pro Tools.</li>
                        <li>Create a new audio track.</li>
                        <li>Click on an empty insert slot.</li>
                        <li>Use the built-in search engine and type "FIRETHEGOLD".</li>
                        <li>Select FIRETHEGOLD.</li>
                    </ol>
                    <img src="/images/plugin_install_5.png" style={{maxWidth:"100%"}} />
                    <p style={{ textAlign: "left", borderLeft: "6px solid #2c5cc5", borderTop: "1px solid #8e8888", borderBottom: "1px solid #8e8888", borderRight: "1px solid #8e8888", padding: "12px 0px 12px 12px", borderRadius: "4px", fontSize: "14px", fontWeight: "400", textAlign: "left", textIndent: "0px", display: "inline !important" }}>
                        For other audio hosts (DAW or NLE), read how to load plug-ins in the user guide.
                    </p>
                    <p style={{ textAlign: "left", borderLeft: "6px solid #e86f25", borderTop: "1px solid #8e8888", borderBottom: "1px solid #8e8888", borderRight: "1px solid #8e8888", padding: "12px 0px 12px 12px", borderRadius: "4px", fontSize: "14px", fontWeight: "400", textAlign: "left", textIndent: "0px", display: "inline !important" }}>
                        The Acustica Audio single point of contact (SPOC) is the Acustica Audio help-desk portal. In order to access to Audio help-desk portal go to the Acustica Audio website and then to the support section. We do not provide help, assistance, or support via social networks, public forums, or through our email accounts.
                    </p>
                    <table data-identifyelement="480" style={{ "width": "100%" }}>
                        <tbody data-identifyelement="481">
                            <tr data-identifyelement="482">
                                <td data-identifyelement="483" style={{ "width": "100%", textAlign: "center", fontWeight:"bold", backgroundColor: "rgb(246, 202, 28)", padding: "0" }}>
                                    <p data-identifyelement="484" dir="ltr"><span data-identifyelement="485" dir="ltr" style={{ fontSize: "14px" }}>Do not send us emails or reply to the help-desk solutions since the ticket will be created without any property, and our help-desk agents will not answer them.</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            </div>
        </>
    );
}