import React from 'react';
import { useShop } from '../context/Shopcontext';

/**
 * Basic cart component. It lists items in the cart (using new Cart API),
 * removes items, and links to a checkout page if desired.
 */

const ShopifyCart = () => {
  const { cart, removeFromCart, getCheckoutUrl } = useShop();

  const lineItems = cart?.lines?.edges || [];

  return (
    <div>
      <h1>Shopping Cart</h1>
      {!lineItems.length && <p>Your cart is empty.</p>}
      <ul>
        {lineItems.map(({ node }) => {
          const { id, quantity, merchandise } = node;
          const price = merchandise?.price?.amount || '0.00';
          const title = merchandise?.product?.title || 'Untitled product';
          return (
            <li key={id}>
              <h2>{title}</h2>
              <p>{quantity} x ${price}</p>
              <button onClick={() => removeFromCart(id)}>Remove</button>
            </li>
          );
        })}
      </ul>
      {lineItems.length > 0 && (
        <a href={getCheckoutUrl()}>
          <button>Proceed to Checkout</button>
        </a>
      )}
    </div>
  );
};

export default ShopifyCart;