import axios from "../../utils/axios";

export function FetchProducts() {
    return axios.get('/shop/products')
}

export function FetchSingleProduct(handle) {
    return axios.get(`/shop/products/${handle}`)
}

export function getCheckoutUrl(items) {
    return axios.post(`/shop/createOrder`, { cartItems: items })
}