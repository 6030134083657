import Remove from "@mui/icons-material/Remove";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

import makeStyles from "@mui/styles/makeStyles";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { emptyCart, removeCartItem } from "../../redux/action/cart.action";
import IconCart from "../svgAssets/IconCart";
import { getCheckoutUrl } from "../../redux/action/shop.action";
import { toast } from "material-react-toastify";
import { useShop } from "../../context/Shopcontext";

export default function CartDrawer(props) {
  const classes = useStyle();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const cart = useSelector((state) => state.cart.items);

  const { cart, removeFromCart, getCheckoutUrl, clearCart, refreshCart } = useShop();
  const lineItems = cart?.lines?.edges || [];

  const handleShopifyCheckout = () => {
    let items = Object.values(cart)
    getCheckoutUrl(items)
      .then(res => {
        console.log(res.data)
        let url = res.data.cartCreate?.cart?.checkoutUrl
        dispatch(emptyCart())
        window.location.href = url
      })
      .catch(err => {
        toast.error(err.message ?? "Some error occured!")
      })
  }

  return (
    <Box
      padding="0 20px"
      width="320px"
      sx={{ width: { xs: "320px", sm: "350px" } }}
      role="shoppingCart"
      className={classes.cartDrawer}
      // onClick={props.toggle(false)}
      onKeyDown={props.toggle(false)}
    >
      <List>
        <ListItem key="cartHead">
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="initial">
              SHOPPING CART
            </Typography>

            <IconButton color="primary" onClick={props.toggle(false)}>
              <Box display="flex" position="relative">
                <IconCart color="primary" />
                {
                  lineItems?.length > 0 && (
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" position="absolute" top={-10} right={-10} borderRadius="50%" width={20} height={20} bgcolor="secondary.main" color="white">
                      <Typography variant="caption" color="initial">{lineItems.length}</Typography>
                    </Box>
                  )
                }

                {/* {lineItems?.length > 0 && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={classes.cartItemNo}
                    justifyContent="center"
                  >
                    <Typography
                      align="center"
                      variant="caption"
                      color="textSecondary"
                    >
                      {props.itemsInCart}
                    </Typography>
                  </Box>
                )} */}
              </Box>
            </IconButton>
          </Box>
        </ListItem>
        {lineItems.map(({ node }) => {
          const { id, quantity, merchandise } = node;
          const price = merchandise?.price?.amount || '0.00';
          const title = merchandise?.product?.title || 'Untitled product';
          return (
            <Fragment key={id}>
              <ListItem>
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="h6"
                    color="initial"
                    style={{ fontWeight: "bold", textTransform: "uppercase" }}
                  >
                    {title}
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="h6" color="initial">
                        PRICE :
                      </Typography>
                      &nbsp; &nbsp;
                      <Typography variant="h4" color="secondary">
                        $ {price}
                      </Typography>
                    </Box>
                    <IconButton onClick={() => removeFromCart(id)}>
                      <Remove className={classes.customRemoveIcon} />
                    </IconButton>
                  </Box>
                </Box>
              </ListItem>
            </Fragment>)
        })}
    
        <ListItem>
          {lineItems.length > 0 ? (
            <a target="_blank" href={getCheckoutUrl()} style={{width: "100%"}}>
              <Button
                fullWidth
                color="secondary"
                variant="outlined"
                // component={Link}
              // to="/payment"
              // onClick={props.toggle(false)}
              onClick={refreshCart}
              >
                BUY
              </Button>
            </a>
          ) : (
            <div
              style={{
                width: "100%",
                height: "80vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <ProductionQuantityLimitsIcon style={{ fontSize: 80 }} />
              <Typography variant="h5" align="center">
                No items in cart
              </Typography>
            </div>
          )}
        </ListItem>
      </List>
    </Box>
  );
}

const useStyle = makeStyles((theme) => ({
  profileDrawer: {
    padding: "30px",
  },
  menuItem: {
    width: "100%",
  },
  greyDivider: {
    color: "#dbdbdb",
  },
  signout: {
    paddingLeft: "16px",
  },
  cartItemNo: {
    position: "absolute",
    top: 0,
    left: 10,
    borderRadius: "25px",
    width: "14px",
    height: "14px",
    backgroundColor: theme.palette.secondary.main,
  },
}));
